<template>
    <div class="Login__Page">
        <div class="is-flex is-flex-direction-row-reverse">
            <div class="Login__Page__Figure">
                <div class="img-responsive">
                    <img src="@/assets/login.svg" alt="">
                </div>
            </div>
            <div class="Login__Page__Form p-6 is-flex is-flex-direction-row
                is-align-items-center">
                 <section class="is-flex is-flex-direction-column">
                    <h1>איפוס סיסמה</h1>
                    <h3 class="subtitle">אנא הזן את כתובת האמייל שלך</h3>
                    <form
                        @submit.prevent="reset">
                        <b-field label="אימייל">
                            <b-input placeholder=""
                                v-model="email"
                                type="email"
                                validation-message="כתובת המייל שהוזנה אינה תקנית"
                                icon-right="close-circle"
                                icon-right-clickable
                                @icon-right-click="clearIconClick">
                            </b-input>
                        </b-field>

                        <b-button :loading="loading"
                            native-type="submit" type="is-primary">אפס סיסמה</b-button>
                            <router-link to="/login">חזור למסך ההתחברות</router-link>
                        <b-message v-if="isSent" class="mt-5" type="is-success">
                            העזרה בדרך! שלחנו לך מייל איפוס סיסמה לכתובת המייל.
                            <br />
                            במידה ולא קיבלת את כתובת המייל <a @click.prevent="reset" href="">לחץ כאן</a> לשליחה חוזרת
                        </b-message>
                    </form>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
    name: 'Forgot',
    data() {
        return {
            email: this.$route.query.email,
            loading: false,
            isSent: false,
        };
    },
    methods: {
        searchIconClick() {
            alert('You wanna make a search?');
        },
        clearIconClick() {
            this.email = '';
        },
        reset() {
            this.loading = true;
            if (this.email) {
                AuthService.forgotPassword({
                    email: this.email,
                })
                .then(() => {
                    this.isSent = true;
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },
    },
};
</script>

<style lang="scss">
.Login__Page {
    & > div {
        height: 100vh;
    }
}
.Login__Page__Form {
    flex: 0 1 600px;

    section {
        width: 100%;

        h1 {
            font-size: 25px;
            text-align: right;
        }
        .subtitle {
            margin: 5px 0 20px 0;
            font-size: 17px;
            color: #7b7b7b;
        }

        .label {
            font-size: 15px;
            font-weight: normal;
            color: #505050;

            &:not(:last-child) {
                margin: 0;
            }
        }
        input {
                direction: ltr;
            }
        button {
            margin: 10px 0;
            width: 100%;
        }

        .message-body {
            border-width: 0 4px 0 0;
            padding: .6em .6em;
        }
    }
}
.Login__Page__Figure {
    flex: 1 1 300px;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
