<template>
    <div class="Login__Page">
        <div class="is-flex is-flex-direction-row-reverse">
            <div class="Login__Page__Figure">
                <div class="img-responsive">
                    <img src="@/assets/login.svg" alt="">
                </div>
            </div>
            <div class="Login__Page__Form p-6 is-flex is-flex-direction-row
                is-align-items-center">
                 <section class="is-flex is-flex-direction-column">
                    <h1>איפוס סיסמה</h1>
                    <h3 class="subtitle">בחירת סיסמה חדשה</h3>
                    <form
                        @submit.prevent="reset">
                        <b-field  label="סיסמא">
                            <b-input type="password"
                                v-model="password"
                                placeholder=""
                                password-reveal>
                            </b-input>
                        </b-field>

                        <b-field  label="סיסמה חוזרת">
                            <b-input type="password"
                                v-model="confimPassword"
                                placeholder=""
                                password-reveal>
                            </b-input>
                        </b-field>
                         <b-message type="is-danger" v-if="invalidCredentials">
                            Passwords must have at least one non letter or digit character. Passwords must have at least one lowercase ('a'-'z'). Passwords must have at least one uppercase ('A'-'Z').
                        </b-message>
                        <b-button :loading="loading"
                            native-type="submit" type="is-primary">קבע סיסמה</b-button>
                    </form>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
    name: 'Login',
    computed: {
        email() {
            return this.$route.query.userId;
        },
        token() {
            return this.$route.query.token;
        },
    },
    data() {
        return {
            password: null,
            confimPassword: null,
            invalidCredentials: false,
            loading: false,
        };
    },
    methods: {
        reset() {
            this.invalidCredentials = false;
            if (!this.password || this.password !== this.confimPassword) {
                this.invalidCredentials = true;
            }
            this.loading = true;
            AuthService.resetPassword({
                Email: this.email,
                Password: this.password,
                ConfirmPassword: this.confimPassword,
                Code: this.token,
            })
            .catch(() => {
                this.invalidCredentials = true;
                this.loading = false;
            })
            .then(() => {
                this.$auth.login({
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    this.$router.replace({ name: 'dashboard' });
                });
            });
        },
    },
};
</script>

<style lang="scss">
.Login__Page {
    & > div {
        height: 100vh;
    }
}
.Login__Page__Form {
    flex: 0 1 600px;

    section {
        width: 100%;

        h1 {
            font-size: 25px;
            text-align: right;
        }
        .subtitle {
            margin: 5px 0 20px 0;
            font-size: 17px;
            color: #7b7b7b;
        }

        .label {
            font-size: 15px;
            font-weight: normal;
            color: #505050;

            &:not(:last-child) {
                margin: 0;
            }
        }
        input {
                direction: ltr;
            }
        button {
            margin: 10px 0;
            width: 100%;
        }

        .message-body {
            border-width: 0 4px 0 0;
            padding: .6em .6em;
        }
    }
}
.Login__Page__Figure {
    flex: 1 1 300px;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
